import moment from 'moment';
import React, { useState } from 'react';
import {
  BrandingIconCard,
  H2,
  H3,
  H4,
  H5,
  ListingLink,
  ResponsiveImage,
} from '../../../../components';

import classNames from 'classnames';
import { FormattedMessage } from '../../../../util/reactIntl';
import { LISTING_STATE_DRAFT } from '../../../../util/types';
import css from './EditListingBusinessReviewPanel.module.css';
import ReadMore from '../../../../components/ReadMore/ReadMore';

const EditListingBusinessReview = props => {
  const { className, rootClassName, listing } = props;

  // common state for handling accordion
  const [opensAccordion, setOpensAccordion] = useState(['openHours']);

  const classes = classNames(rootClassName || css.root, className);
  const isPublished = listing?.id && listing?.attributes?.state !== LISTING_STATE_DRAFT;
  const unitType = listing?.attributes?.publicData?.unitType;

  const { attributes = {}, images = [] } = listing || {};
  const { title, description } = attributes || {};
  const publicData = attributes?.publicData || {};
  const { entries = [] } = attributes?.availabilityPlan || {};

  const {
    business_category,
    socialHandles,
    business_email,
    business_phone_number,
    location: { address },
    city = '',
    state = '',
    country = '',
    ...rest
  } = publicData || {};

  const { instagram, snapchat, pinterest, twitter, facebook, tiktok, website } =
    socialHandles || {};

  return (
    <div className={classes}>
      {/* <H3 as="h1">
        {isPublished ? (
          <FormattedMessage
            id="EditListingBusinessReviewPanel.title"
            values={{ listingTitle: <ListingLink listing={listing} />, lineBreak: <br /> }}
          />
        ) : (
          <FormattedMessage
            id="EditListingBusinessReviewPanel.createListingTitle"
            values={{ lineBreak: <br /> }}
          />
        )}
      </H3> */}

      <H2 as="h2" className={css.title}>
        {title}
      </H2>
      <p className={css.subTitle}>{business_category}</p>

      {/* Images */}
      <div className={css.imageGrid}>
        {images &&
          images.map(image => (
            <ResponsiveImage
              key={image?.id?.uuid}
              image={image}
              variants={['listing-card', 'listing-card-2x']}
              alt={'listing image'}
            />
          ))}
      </div>

      {/* Business Description */}
      <H4 className={css.description}>
        <FormattedMessage id="EditListingBusinessReviewPanel.Description" />
      </H4>

      {/* <ReadMore text={description} maxLength={234} className={css.descriptionText} /> */}

      {/* Social Icons */}
      <div className={css.socialIcons}>
        {website ? (
          <a href={website} target="_blank">
            <BrandingIconCard type="google" />
          </a>
        ) : null}
        {facebook ? (
          <a href={facebook} target="_blank">
            <BrandingIconCard type="facebook" />
          </a>
        ) : null}
        {tiktok ? (
          <a href={tiktok} target="_blank">
            <BrandingIconCard type="tiktok" />
          </a>
        ) : null}
        {twitter ? (
          <a href={twitter} target="_blank">
            <BrandingIconCard type="twitter" />
          </a>
        ) : null}
        {instagram ? (
          <a href={instagram} target="_blank">
            <BrandingIconCard type="instagram" />
          </a>
        ) : null}
        {pinterest ? (
          <a href={pinterest} target="_blank">
            <BrandingIconCard type="pinterest" />
          </a>
        ) : null}
        {snapchat ? (
          <a href={snapchat} target="_blank">
            <BrandingIconCard type="snapchat" />
          </a>
        ) : null}
      </div>

      {/* Open Hours Accordion */}
      <div className={css.accordionWrapper}>
        <div
          className={css.accordion}
          onClick={() =>
            setOpensAccordion(prev =>
              prev.includes('openHours')
                ? [...prev.filter(p => p !== 'openHours')]
                : [...prev, 'openHours']
            )
          }
        >
          <H4 className={css.accordionTitle}>
            <FormattedMessage id="EditListingBusinessReviewPanel.openHours" />
          </H4>
          {opensAccordion.includes('openHours') ? (
            <span>
              <BrandingIconCard type="uparrow" />
            </span>
          ) : (
            <span>
              <BrandingIconCard type="downarrow" />
            </span>
          )}
        </div>
        {opensAccordion.includes('openHours') ? (
          <div id="hours_accordion">
            {entries.map((entry, index) => (
              <div key={index}>
                <H5 className={css.dayText}>{moment(entry?.dayOfWeek, 'ddd').format('dddd')}</H5>
                <p className={css.accordionText}>
                  {moment(entry?.startTime, 'HH:mm').format('hh:mm A')} -{' '}
                  {moment(entry?.endTime, 'HH:mm').format('hh:mm A')}
                </p>
              </div>
            ))}
          </div>
        ) : null}

        {/* Address Accordion */}
        <div
          className={css.accordion}
          onClick={() =>
            setOpensAccordion(prev =>
              prev.includes('address')
                ? [...prev.filter(p => p !== 'address')]
                : [...prev, 'address']
            )
          }
        >
          <H4 className={css.accordionTitle}>
            <FormattedMessage id="EditListingBusinessReviewPanel.Address" />
          </H4>
          {opensAccordion.includes('address') ? (
            <span>
              <BrandingIconCard type="uparrow" />
            </span>
          ) : (
            <span>
              <BrandingIconCard type="downarrow" />
            </span>
          )}
        </div>
        {opensAccordion.includes('address') ? (
          <div
            className={css.accordionText}
            id="address_accordion"
          >{`${address} ${city} ${state} ${country}`}</div>
        ) : null}

        {/* Contact Accordion */}
        <div
          className={css.accordion}
          onClick={() =>
            setOpensAccordion(prev =>
              prev.includes('contactDetails')
                ? [...prev.filter(p => p !== 'contactDetails')]
                : [...prev, 'contactDetails']
            )
          }
        >
          <H4 className={css.accordionTitle}>
            <FormattedMessage id="EditListingBusinessReviewPanel.contactDetails" />
          </H4>
          {opensAccordion.includes('contactDetails') ? (
            <span>
              <BrandingIconCard type="uparrow" />
            </span>
          ) : (
            <span>
              <BrandingIconCard type="downarrow" />
            </span>
          )}
        </div>
        {opensAccordion.includes('contactDetails') ? (
          <div className={css.accordionText} id="contact_accordion">
            {business_email ? <p className={css.accordionText}>Email - {business_email}</p> : null}
            {business_phone_number ? (
              <p className={css.accordionText}>Phone number - {business_phone_number}</p>
            ) : null}
          </div>
        ) : null}

        {/* Additional Details Accordion */}
        <div
          className={css.accordion}
          onClick={() =>
            setOpensAccordion(prev =>
              prev.includes('additionalDetails')
                ? [...prev.filter(p => p !== 'additionalDetails')]
                : [...prev, 'additionalDetails']
            )
          }
        >
          <H4 className={css.accordionTitle}>
            {' '}
            <FormattedMessage id="EditListingBusinessReviewPanel.additionalDetails" />
          </H4>
          {opensAccordion.includes('additionalDetails') ? (
            <span>
              <BrandingIconCard type="uparrow" />
            </span>
          ) : (
            <span>
              <BrandingIconCard type="downarrow" />
            </span>
          )}
        </div>
        {opensAccordion.includes('additionalDetails') ? (
          <div id="additional_accordion">
            <p className={css.accordionText}>-----------------</p>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EditListingBusinessReview;
